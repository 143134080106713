<script>

import http from '@/app-http'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import gmapsInit from '@/gmaps';
import { debounce } from "lodash";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

const tagSvg = `<svg class="svg-inline--fa fa-tag" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tag" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path class="" fill="currentColor" d="M48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L418.7 226.7C443.7 251.7 443.7 292.3 418.7 317.3L285.3 450.7C260.3 475.7 219.7 475.7 194.7 450.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5V80C0 53.49 21.49 32 48 32L48 32zM112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176z"></path></svg>`;

export default {
  components: {
   
  },
  props: {
    filter: {
      type: Object
    },
    center: {
      type: Object
    },
    directoryId: {
       type: String
    }
  },
  async mounted () {
    window.addEventListener("resize", this.debCalculateHeight);
    this.debCalculateHeight();

    const google = await gmapsInit();
    this.google = google;

    if (!this.center && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.initMap({lat: position.coords.latitude, lng: position.coords.longitude})
      }, () => this.initMap(this.center), {timeout:10000});
    } else {
      this.initMap(this.center)
    }    
  },
  beforeUnmount() {
   
  },
  unmounted() {
    window.removeEventListener("resize", this.debCalculateHeight);
    if(this.map)
      this.google.maps.event.clearListeners(this.map);
  },
  data() {
    return {
      markers: {},
      items: []
    };
  },
  computed :{
    ...mapGetters('filters', {
      filters: 'filters'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('directories', {
      directories: 'items'
    }),
    ...mapGetters('directoryItems', {
      changedItems: 'changedItems',

    })
  },
  watch:{
    filter(newValue, oldValue) {
      if(newValue !== oldValue) {
        console.log('filter changed')
        this.debSearch();
      }
    },
    center(newValue, oldValue) {
      if(newValue != oldValue) {
        if(this.map) {
          this.map.setCenter(this.center);
        }
      }
    },
    directoryId(newValue, oldValue) {
      if(newValue != oldValue) {
        this.load();
      }
    },
    changedItems(newValue, oldValue) {
      if(newValue.length != oldValue.length) {
        this.load();
      }
    }
  },
  methods:{
     ...mapMutations('directoryItems', {
       openItem: 'OPEN_ITEM'
    }),
    ...mapActions('directoryItems', {
      loadById: 'loadById'
    }),
     debLoad: debounce(function(){
      this.load();
    }, 600),
    initMap(center) {
      if(this.map)
        return;
      if(!center) {
        center = { lat: (this.address ||{}).lat || 50.4501, lng: (this.address || {}).lng || 30.5234 };
      }
      let map = new this.google.maps.Map(this.$refs.map, {
        center: center,
        zoom: 8,
      });
    
      this.map = map;

      this.infowindow = new this.google.maps.InfoWindow({
        content: '',
      });

      this.google.maps.event.addListener(this.infowindow, 'domready', () => {
          console.log('a[domready]');

        if(this.infowindow.marker) {
          document.getElementById('gmap_infowindow_header_a').addEventListener("click", () => {
            this.onShowForm(this.infowindow.marker.mapObj, this.infowindow.marker.model);
          });
          const hrefs = document.querySelectorAll('a[data-item]');
          hrefs.forEach(a => {
            a.addEventListener("click", () => {
              const itemId = a.getAttribute('data-item');
              const defId = a.getAttribute('data-item-def');
              // const directory = this.directories.find(x => x.id === defId);
              // console.log('a[data-item]', a);
              this.openItem({ item: { id: itemId, directoryId: defId}, mode: 'view'});
            });
          })
        }
      });

      this.google.maps.event.addListener(this.map, 'bounds_changed', this.debLoad);
      this.load();
    },
    debCalculateHeight() {
      this.$nextTick(()=> this.calculateHeight());
      window.setTimeout(() => {
         this.calculateHeight()
      }, 1000);
    },
    calculateHeight() {
      const table = document.getElementById('map') || this.$refs['map'];
      const windowHeight = window.innerHeight;
      const toolbar = document.getElementById("user-toolbar");
      const topBar = document.getElementById("page-topbar");

      if(table && toolbar) {
        const o = topBar.offsetHeight + toolbar.offsetHeight 
        + 40 //paddings
        ;
        const boardMaxHeight = windowHeight - o; // topbar + paddings

        table.style.maxHeight = boardMaxHeight + 'px';  
        table.style.height = boardMaxHeight + 'px';  
      }
    },
    onShowForm(mapObj, model) {
      const directory = this.directories.find(x => x.id === mapObj.directoryId);
      if(directory) {
        this.openItem({ item: model, mode: 'view'});
      }
    },
    async showInforWindow(marker){
         
      let content = `<a id="gmap_infowindow_header_a" style="margin-bottom:10px; display:inline-block" href="javascript:void(0)"><b>#${marker.mapObj.number} ${marker.mapObj.display || ''}</b></a>`;
      content += `<p>${marker.mapObj.address.address}</p>`;
      content += '<p>Loading...</p>';
      
      this.infowindow.marker = null;
      this.infowindow.setContent(content);
      this.infowindow.open({
        anchor: marker,
        map:this.map,
        shouldFocus: false,
      });

      const directory = this.directories.find(x => x.id === marker.mapObj.directoryId);

      let obj = {};

      if(directory) {
        const dir = await this.loadById( { directoryId: directory.id, id: marker.mapObj.directoryItemId });
        obj = { ...dir.customFields, relatedActiveProcesses: dir.relatedActiveProcesses, tags: dir.tags };
        marker.model = dir;
      }
      if(obj) {
        content = `<a id="gmap_infowindow_header_a" style="margin-bottom:10px; display:inline-block" href="javascript:void(0)"><b>#${marker.mapObj.number} ${marker.mapObj.display || ''}</b></a>`;
        if(obj.tags && obj.tags.length > 0) {
          obj.tags.forEach(tag => {
            content += "<span class='badge badge-soft-dark me-2 mb-1'>";
            content += tagSvg;
            content += tag;
            content += "</span>";
          });
        }

        content += `<p>${marker.mapObj.address.address}</p>`;

        /* render fields >>> */
        content += "<dl>";
        directory.fields.forEach(field => {
          if(fieldType === 'Table') {
            return;
          }
           if(fieldType === 'Address') {
            return;
          }
          let strVal = null;
          const fieldType = field.type;
          const fieldVal = obj[field.id];

          if(fieldVal === null || fieldVal === undefined)
            return;

          if(fieldType === 'String') {
            strVal = fieldVal;
          } else if(fieldType === 'Number') {
             strVal = fieldVal;
          } else if(fieldType === 'PhoneNumber') {
             strVal = fieldVal;
          } else if(fieldType === 'Text') {
             strVal = fieldVal;
          } else if(fieldType === 'Link') {
             strVal = `<a href='${fieldVal}'>${fieldVal}</a>`;
          } else if(fieldType === 'Directory') {
             strVal = fieldVal ? (fieldVal.display || ('#' + fieldVal.number)) : '';
          } else if(fieldType === 'User') {
             strVal = fieldVal ? (fieldVal.name) : '';
          } else if(fieldType === 'Date') {
             strVal = this.$filters.dateOnly(fieldVal);
          } else if(fieldType === 'Dropdown') {
             strVal = (field.dropdownItems.find(x => x.value === fieldVal) || {}).text;
          }

          if(strVal !== null && strVal !== undefined) {
            content += "<dt>" + field.name;
            content += "</dt>";
            content += "<dd>";
            content += strVal;
            content += "</dd>";
          }
          
        });
        content += "</dl>";
        /* render fields <<< */

        /* render related processes >>> */     
        if(obj.relatedActiveProcesses && obj.relatedActiveProcesses.length > 0) {
          content += "<table class='table-borderless table table-sm'>";
         
          obj.relatedActiveProcesses.forEach(p => {
            const pDef =  this.directories.find(x => x.id === p.directoryId);
            const statusDef = pDef.statusList.find(x => x.id === p.status);
            if(pDef) {
              content += "<tr>";
              content += "<td><a href='javascript:void(0)' data-item='" + p.directoryItemId + "' data-item-def='" + p.directoryId + "'>" +  pDef.name + " [#" + p.number + "]</a></td>";
              content += "<td><span class='state-name'><span class='state-name-circle me-1' style='background-color:" + statusDef.color + "'></span> " + statusDef.name + "</span></td>";
              //content += "<td class='text-truncate' style='max-width:300px'>" + p.display + "</td>";
              content += "</tr>";
            }
          });
          content += "</table>";
        }
        /* render related processes <<< */  

        this.infowindow.marker = marker;
        this.infowindow.setContent(content);
      }
    },
    drawMarkers() {
    
      if(!this.map) return;

      const markers = {};
      const markersArray = [];

      this.items.forEach(mapObj => {

        const pos = { lat: mapObj.address.lat, lng: mapObj.address.lng };
        const directory = this.directories.find(x => x.id === mapObj.directoryId);
        if(!directory) return;
        let field = directory.fields.find(x => x.id === mapObj.fieldId);
        if(!field) return;
        if(mapObj.subFieldId) {
          if(!field.tableFields) return;
          field= field.tableFields.find(x => x.id === mapObj.subFieldId);
          if(!field) return;
        } 
        let gMarker = this.markers[mapObj.uniqueKey];
        const markerSvgPath = "M0 32a10 10 0 0 0-10 10c0 9 10 20 10 20s10-11 10-20a10 10 0 0 0-10-10zm0 12a2 2 0 1 1 2-2 2 2 0 0 1-2 2z";
        // const activeSvgPath = `M 100, 100
        // m -5, 0
        // a 5,5 0 1,0 10,0
        // a 5,5 0 1,0 -10,0`;

        if(gMarker) {
          gMarker.setPosition(pos);
         
          gMarker.setTitle(mapObj.title);
          gMarker.mapObj = mapObj;
        } else {
          gMarker = new this.google.maps.Marker ({
            position: pos,
            map: this.map,
            title: mapObj.address.address,
          });
         
          var indicatorMarker = new this.google.maps.Marker({
            position: gMarker.getPosition(),
            map: this.map,
            icon: { 
              path: "M 100 100 L 150 100 L 150 115 L 100 115 z",
              scale: 0.5 * (field.mapMarkerScale || 1),
              fillColor: "red",
              fillOpacity: 1.0,
              strokeColor: "black",
              strokeWeight: 1,
              anchor: new this.google.maps.Point(125,100)
            }
          });
          indicatorMarker.bindTo("position", gMarker);
         
          gMarker.mapObj = mapObj;
          gMarker.indicator = indicatorMarker;
          indicatorMarker.mapObj = mapObj;
          
          gMarker.addListener("click", () => {
            this.showInforWindow(gMarker);
           
          });
          indicatorMarker.addListener("click", () => {
            this.showInforWindow(indicatorMarker);
          });
        }

        gMarker.setIcon({
          path: markerSvgPath,
          fillColor: field.mapMarkerColor || '#FF0000',
          fillOpacity: 1,
          anchor: new this.google.maps.Point(0,64),
          strokeColor: "black",
          strokeWeight: 1,
          scale: field.mapMarkerScale || 1
        });

        if(mapObj.relatedActiveProcesses && mapObj.relatedActiveProcesses.length > 0) {
          const p = mapObj.relatedActiveProcesses[0];
          const pDirDef = this.directories.find(x => x.id === p.directoryId);
          const statusDef = pDirDef.statusList.find(x => x.id === p.status);

          gMarker.indicator.setIcon({
            path: "M 100 100 L 150 100 L 150 115 L 100 115 z",
            scale: 0.5 * (field.mapMarkerScale || 1),
            fillColor: (statusDef || {}).color,
            fillOpacity: 1.0,
            strokeColor: "black",
            strokeWeight: 1,
            anchor: new this.google.maps.Point(125,100)
          });
          gMarker.indicator.setVisible(true);
        } else {
          gMarker.indicator.setVisible(false);
        }
      
        markers[mapObj.uniqueKey] = gMarker;
        markersArray.push(gMarker);
      });
     
      const oldKeys = Object.keys(this.markers);
     
      oldKeys.forEach(key =>{
        if(!markers[key]) {
          const marker = this.markers[key];
         
          marker.mapObj = null;
          marker.setMap(null);
          marker.setVisible(false);
          marker.indicator.setVisible(false);
          marker.indicator.setMap(null);
          this.google.maps.event.clearListeners(marker.indicator);
          this.google.maps.event.clearListeners(marker);
        }
      });

      this.markers = markers;

       if(this.markerCluster) {
          this.markerCluster.clearMarkers(); 
        }
        if(this.markerCluster) {
          this.markerCluster.addMarkers(markersArray);
        } else {
          this.markerCluster = new MarkerClusterer({ map: this.map, markersArray });
        }
    },
    debSearch: debounce(function(){
      this.load();
    }, 600),
    async load() {
      if(this.__timer){
        window.clearTimeout(this.__timer);
      }

      const bounds = this.map.getBounds();
      if(bounds && this.directoryId) {
        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();
        const req = {
          directoryId: this.directoryId,
          east: ne.lng(),
          north: ne.lat(),
          south: sw.lat(),
          west: sw.lng(),
          searchReq: {
            tags: this.filter ? this.filter.tags : [],
            tagsLgOp: this.filter ? this.filter.tagsLgOp : 'And',
            keyword: this.filter ? this.filter.keyword : null,
            keywordLgOp: this.filter ? this.filter.keywordLgOp : null,
            statusList: this.filter ? this.filter.statusList : null,
            statusLgOp: this.filter ? this.filter.statusLgOp : null,
            conditions: this.filter ? (this.filter.conditions || [] ) : null,
            skip:0,
            take:100000,
            orderBy: {
              field:"Number"
            }
          }
        };
        const response = await http.post('map-search', req);
        this.items = response.data.items;
      } else {
        this.items = [];
      }
      
      this.drawMarkers();

      this.__timer = window.setTimeout(() => {
        this.load();
      }, 60000);
    }
  }
};
</script>

<template>
 <div class="m-0">
    <div
      style="position:relative;"
      ref="map"
      id="map"
    ></div>
  </div>
</template>
