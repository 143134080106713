<script>
import Layout from "../../layouts/main";
import {  mapGetters, mapActions } from 'vuex';
import { queryBuilderDefaultQueryObj } from '@/helpers';
import Map from "./map";
import gmapsInit from '@/gmaps';
import FilterRightSidebar from "../directory-items/filter-right-sidebar.vue";
import Tags from '@/components/tags'


export default {
  components: {
    Layout,
    FilterRightSidebar,
    Map,
    Tags
  },
  data() {
    return {
     initializing: true,
     mapCenter: null,
     selectedDirectoryId: null
    };
  },
  async mounted() {
    try {
      const google = await gmapsInit();
      this.google = google;
      const options = {
        language: this.currentUser.account.lang,
        fields: [ "geometry", "formatted_address"]
      };
      console.log('this.$refs', this.$refs);
      const autocomplete = new google.maps.places.Autocomplete(this.$refs.autocompleteInput, options);
      this.autocomplete = autocomplete;
      this.autocomplete.addListener('place_changed', this.googlePlaceChanged);
    } catch (error) {
      console.error(error);
    }
  },
  created() {
    this.init();
    document.title = this.pagetitle;
    if(!this.mapEnabled) {
      this.$router.push({name: 'home'});
    }
  },
  computed :{
    
     ...mapGetters('filters', {
      filters: 'filters'
    }),
    ...mapGetters('directories', {
      allDirectories: 'items',
      mapDirectories: 'directoriesOnMap'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      mapEnabled: 'mapEnabled'
    }),
    ...mapGetters('directoryItems', {
      allTags: 'tags'
    }),
    ...mapGetters('boot', {
      ready: 'ready'
    }),
    isBusy () {
      return this.initializing;
    },
    pagetitle () {
      return this.$t('common.map');
    },
    directory () {
      return this.directories.find(x => x.id === this.selectedDirectoryId) || { permissions: [], visibility: [] };
    },
    tags() {
      return (this.allTags || {})[this.selectedDirectoryId] || [];
    },  
    filterKey() {
      const key = (this.selectedDirectoryId) + '-map';
     
      return key;
    },  
    filter(){
      return this.filters[this.filterKey] || null;
    },
    filterTags:
    {
      get: function() {
        return (this.filter || {}).tags || [];
      },
      set: function(newValue) {
        const data = JSON.parse(JSON.stringify(this.filter || queryBuilderDefaultQueryObj()));
        data.tags = newValue;
        if(!data.tagsLgOp)
          data.tagsLgOp = 'And';
        this.setFilter({key: this.filterKey, value: data });
      }
    },
    keyword:
    {
      get: function() {
        return (this.filter || {}).keyword || "";
      },
      set: function(newValue) {
        const data = JSON.parse(JSON.stringify(this.filter || queryBuilderDefaultQueryObj()));
        data.keyword = newValue;
        if(!data.keywordLgOp)
          data.keywordLgOp = 'And';
        this.setFilter({key: this.filterKey, value: data });
      }
    }
  },
  watch:{
    ready() {
      this.init();
    }
  },
  methods: {
    ...mapActions('filters', {
      loadFilterUserSettings: 'loadUserSettings',
       setFilter: 'setFilter'

    }),
    googlePlaceChanged() {
      var place = this.autocomplete.getPlace();

      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        // Do anything you like with what was entered in the ac field.
     //   console.log('You entered: ', { place, r: this.autocomplete.removeListener });
        return;
      }

      //console.log('You selected: ', { place, r: this.autocomplete.removeListener });
      const newValue = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        address: place.formatted_address
      };
    
     // console.log('Map new center: ', { newValue });

      this.mapCenter = newValue;
      //this.$emit('update', val); 
    },
    async init() {
      if(this.ready) {
        this.initializing = true;
        await this.loadFilterUserSettings();
        if(this.mapDirectories.length > 0) {
          this.selectedDirectoryId = this.mapDirectories[0].id; 
        }
        // await this.loadObjectTypes();
        // if(this.hasContactEntity) {
        //   this.setSelectedEntity('Contact');
        // } else {
        //   if(this.processTypes.length > 0) {
        //     this.setSelectedEntity(this.processTypes[0].id);
        //   } else if(this.directories.length > 0) {
        //     this.setSelectedEntity(this.directories[0].id);
        //   }
        // }
        this.initializing = false;
      }
     
    },
    toggleFilter () {
      document.body.classList.toggle("right-bar-enabled");
    },
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle" :is-busy="isBusy">

    <filter-right-sidebar v-if="selectedDirectoryId && !initializing" :directory-id="selectedDirectoryId" :filter-key="filterKey" >
    </filter-right-sidebar>

    <div class="row"  ref="toolbar" id="user-toolbar">
      <div class="col-auto">
        <select class="form-control" v-if="mapDirectories.length > 0" v-model="selectedDirectoryId">
          <option v-for="dir in mapDirectories" :key="dir.id" :value="dir.id">{{dir.name}}</option>
        </select>
      </div>
      <div class="col-auto">
        <div class="d-none d-lg-block" style="position:relative;min-width:150px">
          <input class="form-control" :placeholder="$t('common.search')" style="padding-left:30px" v-model="keyword"/>
          <span class="text-muted" style="position:absolute; top:9px; left:10px" >
            <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
          </span>
        </div>
      </div>
      <div class="col-auto">
        <div class="d-none d-lg-block" style="position:relative;">
          <tags :tags="tags" :value="filterTags" @update="tags => filterTags = tags" :editable="true" :add-tag-text="$t('common.searchByTag')"></tags>
        </div>
      </div>
      <div class="col text-end">
        <div
          class="d-flex flex-wrap align-items-center justify-content-end "
        >
          <div>
          <input class="form-control" ref="autocompleteInput" style="min-width:250px"/>
          </div>
          <div class="ms-2">
            <div class="dropdown d-inline-block">
              <button
                type="button"
                class="btn btn-secondary"
               
                @click.stop.prevent="toggleFilter(slotProps)"
              >
                <i class="fa fa-filter "></i>
                <div class="text-sm-end d-none d-md-inline-block ms-2">
                  {{$t('common.filters')}}
                </div>
              </button>
            </div>
          </div> 
          <!-- <div class="ms-3  mb-1" v-if="currentUser.isAdmin">
            <router-link :to="{name:'custom-fields', params: { entity: 'contact' }}" class="btn btn-outline-secondary waves-effect">
              <i class="mdi mdi-cogs me-1"></i> 
              <div class="text-sm-end d-none d-md-inline-block ms-2">{{ $t("map.settings") }}</div>
            </router-link>
          </div> -->
        </div>
      </div>
    </div>
    <div class="bg-white" style="margin-top:10px">
      <Map v-if="!initializing && selectedDirectoryId" :center="mapCenter" :directoryId="selectedDirectoryId" :filter="filter"></Map>
    </div>
  </Layout>
</template>
